import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ref, onUnmounted } from "vue";

const config = {
  apiKey: "AIzaSyAOPq69ABJgyfC36-vIQdasKfVgou5FgD4",
  authDomain: "noma-quiz.firebaseapp.com",
  projectId: "noma-quiz",
  storageBucket: "noma-quiz.appspot.com",
  messagingSenderId: "320222250630",
  appId: "1:320222250630:web:e96baec1d3a44a249ab307",
  measurementId: "G-CFE1365C51",
};

const firebaseApp = firebase.initializeApp(config);

const db = firebaseApp.firestore();
const usersCollection = db.collection("users");

export const createUser = (user) => {
  return usersCollection.add(user);
};

export const getUser = async (id) => {
  const user = await usersCollection.doc(id).get();
  return user.exists ? user.data() : null;
};

export const updateUser = (id, user) => {
  return usersCollection.doc(id).update(user);
};

export const deleteUser = (id) => {
  return usersCollection.doc(id).delete();
};

export const useLoadUsers = () => {
  const users = ref([]);
  const close = usersCollection.onSnapshot((snapshot) => {
    users.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  });
  onUnmounted(close);
  return users;
};

const questionsCollection = db.collection("questions");

export const useLoadQuestions = () => {
  const questions = ref([]);
  const close = questionsCollection.onSnapshot((snapshot) => {
    questions.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  });
  onUnmounted(close);
  return questions;
};

export const getQuestion = async (id) => {
  const question = await questionsCollection.doc(id).get();
  return question.exists ? question.data() : null;
};

export const createQuestion = (question) => {
  return questionsCollection.add(question);
};

export const deleteQuestion = (id) => {
  return questionsCollection.doc(id).delete();
};

const quizzesCollection = db.collection("quizzes");

export const useLoadQuizzes = () => {
  const quizzes = ref([]);
  const close = quizzesCollection.onSnapshot((snapshot) => {
    quizzes.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  });
  onUnmounted(close);
  return quizzes;
};

export const findQuiz = async (topic, level) => {
  const quiz = ref([]);
  const close = await quizzesCollection
    .where("topic", "==", topic)
    .where("level", "==", parseInt(level))
    .limit(1)
    .get()
    .then((querySnapshot) => {
      quiz.value = {
        id: querySnapshot.docs.at(0).id,
        ...querySnapshot.docs.at(0).data(),
      };
    });
  onUnmounted(close);
  return quiz;
};

export const getQuiz = async (id) => {
  const quiz = await quizzesCollection.doc(id).get();
  return quiz.exists ? quiz.data() : null;
};

export const createQuiz = (quiz) => {
  return quizzesCollection.add(quiz);
};

export const updateQuiz = (id, quiz) => {
  return quizzesCollection.doc(id).update(quiz);
};

export const deleteQuiz = (id) => {
  return quizzesCollection.doc(id).delete();
};
