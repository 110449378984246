import { createRouter, createWebHistory } from "vue-router";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import HomeView from "../views/HomeView.vue";
import EditView from "@/views/EditView.vue";
import SimulateView from "@/views/SimulateView.vue";
import AddQuestionView from "@/views/AddQuestionView.vue";
import RegisterView from "@/views/RegisterView.vue";
import SignInView from "@/views/SignInView.vue";
import QuestionListView from "@/views/QuestionListView.vue";
import QuizView from "@/views/QuizView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: SignInView,
  },
  {
    path: "/questions",
    name: "questions-list",
    component: QuestionListView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add",
    name: "Add Question",
    component: AddQuestionView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit/:id",
    name: "Edit",
    component: EditView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/simulate/:id",
    name: "Simulate",
    component: SimulateView,
  },
  {
    path: "/quiz/:topic/:level",
    name: "Quiz",
    component: QuizView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert("You must be logged in to access this page");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
