<template>
  <div class="home">
    <div><img src="logo.png" /></div>
    <div class="home-header">
      <p>What do you know about the health risks in Northern Ontario?</p>
    </div>
    <div class="card-body">
      <p> <strong> (Brief summary of the project and what people can expect of this website/app). </strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> 
    </div>
    <a href="/quiz/substanceuse/1">
      <div class="card bg-dark text-white">
        <img class="card-img" src="substance.gif" alt="Card image" />
        <div class="card-img-overlay">
          <h5 class="card-title">Substance Use</h5>
          <p class="card-text">
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This content is a little bit longer.
          </p>
          <p class="card-text">Last updated 3 mins ago</p>
        </div>
      </div>
    </a>
    <div class="card bg-dark text-white">
      <img class="card-img" src="transportation.webp" alt="Card image" />
      <div class="card-img-overlay">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">
          This is a wider card with supporting text below as a natural lead-in
          to additional content. This content is a little bit longer.
        </p>
        <p class="card-text">Last updated 3 mins ago</p>
      </div>
    </div>
    <div class="home-header">
      <p>About Us</p>
    </div>
    <div class="card-body"> 
      <p> <strong>We would use this section to talk about NOMA, the project and what we are doing with the information. </strong> Should include links to professional sites. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Montes nascetur ridiculus mus mauris. </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "HomeView",
  components: {},
};
</script>
