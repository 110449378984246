<template>
  <div class="ctr">
    {{ quizValues.topicEN }}
    <div class="questions-ctr">
      <div class="progress-bar">
        <div
          class="bar"
          :style="{
            width: `${(questionsAnswered / questionCount) * 100}%`,
          }"
        ></div>
        <div class="status">
          {{ questionsAnswered }} out of {{ questionCount }} questions answered
        </div>
      </div>
      <div v-show="questionsAnswered < questionCount">
        <quiz-question
          :questionID="currentQuestionID"
          :questionsAnswered="questionsAnswered"
          :answerSelected="answerSelected"
          @question-answered="questionAnswered"
          @next-question="nextQuestion"
        ></quiz-question>
      </div>
      <quiz-results
        v-show="questionsAnswered == questionCount"
        :correctAnswers="correctAnswers"
        :questionsAnswered="questionsAnswered"
      ></quiz-results>
    </div>

    <button
      v-if="questionsAnswered === questionCount"
      type="button"
      class="reset-btn"
      @click.prevent="reset"
    >
      Try Again
    </button>
  </div>
</template>

<script>
/* eslint-disable */
import { reactive, computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { findQuiz, getQuestion, getQuiz, useLoadQuizzes } from "@/firebase";

import QuizQuestion from "@/components/QuizQuestion.vue";
import QuizResults from "@/components/QuizResults.vue";
import AppResult from "@/components/AppResult.vue";

export default {
  name: "QuizView",
  components: {
    QuizQuestion,
    QuizResults,
    AppResult,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const quizTopic = computed(() => route.params.topic);
    const quizLevel = computed(() => route.params.level);

    //output quiz to debug console
    console.log(quizTopic.value);
    console.log(quizLevel.value);

    const quizValues = reactive({
      id: "",
      topic: "",
      topicEN: "",
      level: 0,
      questions: [],
    });

    const questionsAnswered = ref(0);
    const correctAnswers = ref(0);
    const answerSelected = ref(-1);
    const currentQuestionID = ref();
    const questionCount = ref(0);

    onMounted(async () => {
      const quiz = await findQuiz(quizTopic.value, quizLevel.value);
      console.log(quiz.value);

      quizValues.id = quiz.value.id;
      quizValues.level = quiz.value.level;
      quizValues.topic = quiz.value.topic;
      quizValues.topicEN = quiz.value.topic_en;
      quizValues.questions = quiz.value.questions;

      currentQuestionID.value = quizValues.questions[questionsAnswered.value];

      questionCount.value = quizValues.questions.length;

      console.log(quizValues);
      console.log(currentQuestionID);
    });

    /*const question = reactive({
      text: "",
      type: "fact",
      order: "numerical",
      limit: 4,
      options: [],
    });*/

    /*
    onMounted(async () => {
      const questionDB = await getQuestion(questionId.value);
      console.log(questionDB, questionId.value);
      question.text = questionDB.text;
      question.type = questionDB.type;
      question.order = questionDB.order;
      question.limit = questionDB.limit;
      question.options = questionDB.options;
    });*/

    return {
      currentQuestionID,
      questionsAnswered,
      correctAnswers,
      answerSelected,
      quizValues,
      questionCount,
    };
  },
  methods: {
    questionAnswered(is_correct, selected) {
      if (is_correct) {
        this.correctAnswers++;
      }
      this.answerSelected = selected;
      console.log(is_correct);
      //this.nextQuestion();
    },
    nextQuestion() {
      this.answerSelected = -1;
      this.questionsAnswered++;
      this.currentQuestionID =
        this.quizValues.questions[this.questionsAnswered];
    },
    reset() {
      this.questionsAnswered = -1;
      this.correctAnswers = 0;
      this.nextQuestion();
    },
  },
};
</script>
