<template>
  <div class="card card-body mt-4">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label>Text</label>
        <input v-model="form.text" class="form-control" required />
      </div>
      <div class="form-group">
        <label>Type</label>
        <select v-model="form.type" class="form-control" required>
          <option value="fact">Fact</option>
        </select>
      </div>
      <div class="form-group">
        <label>Order</label>
        <select v-model="form.order" class="form-control" required>
          <option value="numerical">Numerical</option>
        </select>
      </div>
      <div class="form-group">
        <label>Limit</label>
        <input v-model="form.limit" class="form-control" required  />  
      </div>

      <template v-for="(option, index) in form.options" :key="index">
        <h3>Option {{ index }} <span v-if="index == 0">(CORRECT)</span></h3>
        
        <div class="form-group">
          <label>Text</label>
          <input v-model="option.text" class="form-control" />
        </div>
        <div class="form-group">
          <label>Answer</label>
          <input v-model="option.answer" class="form-control" />
        </div>
      </template>
      <button @click.prevent="addOption">Add Option</button>
      <button @click.prevent="removeOption">Remove Option</button>

      <button type="submit" class="btn btn-success mt-3">
        Add Question
      </button>
    </form>
  </div>
</template>

<script>
import { createQuestion } from '@/firebase'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const form = reactive({ text: '', type: 'fact', order: 'numerical', limit: 4, options: [{text: '',answer: ''},{text: '',answer: ''} ] })

    const onSubmit = async () => {
      await createQuestion({ ...form })
      //form.text = ''
      //form.type = ''
      
      router.push('/')
    }

    return { form, onSubmit }
  },
  methods: {
    addOption() {
      this.form.options.push({text: '',answer: ''})
    },
    removeOption() {
      this.form.options.pop()
    }
  }


}
</script>
