<template>
  <div class="card mt-4">
    <table class="table m-0">
      <thead>
        <tr>
          <th scope="col">Question</th>
          <th scope="col">Type</th>
          <th scope="col">Order</th>
          <th scope="col">Limit</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="({ id, text, type, order, limit, options }, indexQ) in questions" :key="id">
          <tr>
          <td>Question {{ indexQ }}</td>
          <td>{{ text }}</td>
          <td>{{ type }}</td>
          <td>{{ order }}</td>
          <td>{{ limit }}</td>
          <td>
            <router-link :to="`/simulate/${id}`">
              <button class="btn btn-ok btn-sm me-2">
                Simulate
              </button>
            </router-link>
            <router-link :to="`/edit/${id}`">
              <button class="btn btn-primary btn-sm me-2">
                Edit
              </button>
            </router-link>
            <button class="btn btn-danger btn-sm" @click="deleteQuestion(id)">
              Delete
            </button>
          </td>
        </tr>
        <tr v-for="(option, indexO) in options" :key="indexO">
          <td>Question {{ indexQ }} - Option {{indexO}} <span v-if="indexO == 0">(CORRECT)</span></td>
          <td>{{ option.text }}</td>
          <td colspan="4">{{ option.answer }}</td>
        </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useLoadQuestions, deleteQuestion } from '@/firebase'

export default {
  setup() {
    const questions = useLoadQuestions()
    return { questions, deleteQuestion }
  }
}
</script>
