<template>
  <div class="ctr">
      <transition name="fade" mode="out-in">
        <app-question v-if="questionsAnswered < questions.length" :questions="questions" :questionsAnswered="questionsAnswered" :answerSelected="answerSelected" @question-answered="questionAnswered" @next-question="nextQuestion"></app-question>
        <app-result v-else :results="results" :correctAnswers="correctAnswers"></app-result>
      </transition>

      <button v-if="questionsAnswered === questions.length" type="button" class="reset-btn" @click.prevent="reset">Reset</button>
  </div>
</template>

<script>
/* eslint-disable */
import { reactive, computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getQuestion } from '@/firebase'

import AppQuestion from '@/components/AppQuestion.vue';
import AppResult from '@/components/AppResult.vue';


export default {
  name: 'SimulateView',
  components: {
    AppQuestion,
    AppResult
  },
  setup() {

    const router = useRouter()
    const route = useRoute()
    const questionId = computed(() => route.params.id)
    const question = reactive({ text: '', type: 'fact', order: 'numerical', limit: 4, options: [] })
   
    const questionsAnswered = ref(0)
    const correctAnswers = ref(0)
    const answerSelected = ref(-1)
    
    onMounted(async () => {
      const questionDB = await getQuestion(questionId.value)
      console.log(questionDB, questionId.value)
      question.text = questionDB.text
      question.type = questionDB.type
      question.order = questionDB.order
      question.limit = questionDB.limit
      question.options = questionDB.options
    })
        
    return { 
        questionsAnswered,
        correctAnswers,
        answerSelected,
        questions:  [ {question} ] 
    }
  },  
  methods: {
    questionAnswered(is_correct, selected) {
      if(is_correct) {
        this.correctAnswers++;
      }
      this.answerSelected = selected;
      console.log(is_correct);
      //this.questionsAnswered++;
    },
    nextQuestion() {
      this.answerSelected = -1;
      this.questionsAnswered++;
    },
    reset() {
        this.questionsAnswered = 0;
        this.correctAnswers = 0;
    }
  }
}
</script>