<template>
  <div class="single-question">
    <div class="question">Congratulations!</div>
    <div class="answers">
      <div class="answer">
        You have completed the quiz!<br /><br />
        You have answered {{ correctAnswers }} out of
        {{ questionsAnswered }} questions correctly.
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { reactive, computed, onMounted, ref } from "vue";
import { findQuiz, getQuestion, getQuiz, useLoadQuizzes } from "@/firebase";

export default {
  name: "QuizResults",
  props: ["questionsAnswered", "correctAnswers"],
};
</script>

<style></style>
