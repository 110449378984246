<template>
  <nav>
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item">
        <router-link class="nav-link active" to="/">Home</router-link>
      </li>
      
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" aria-expanded="false">Modules</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#">Substance Use</a>
          <a class="dropdown-item" href="#">Environmental Factors [coming soon]</a>
          <a class="dropdown-item" href="#">Transportation [coming soon]</a>
          <a class="dropdown-item" href="#">Healthcare [coming soon]</a>
          <a class="dropdown-item" href="#">Workplace [coming soon]</a>
        </div>
      </li>
      
      <li class="nav-item">
        <router-link class="nav-link" to="/about">About</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/contact">Contact</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/register">Register</router-link>
      </li>
      <li class="nav-item" v-if="isLoggedIn">
        <router-link class="nav-link" to="/add">Add Question</router-link>
      </li>
      <li class="nav-item" v-if="!isLoggedIn">
        <router-link class="nav-link" to="/sign-in">Sign In</router-link>
      </li>
    </ul> 
    <a @click="handleSignOut" v-if="isLoggedIn" href="#">Sign Out</a>
  </nav>
  <router-view />
  <footer>
    <div class="card-footer">
      <p> <strong> Terms of Use and Privacy Policy. </strong> Include small legal blurb here followed by link for more in depth documentation. </p>
    </div>
  </footer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const isLoggedIn = ref(false);
const router = useRouter();

let auth;
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    isLoggedIn.value = !!user;
  });
});

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      isLoggedIn.value = false;
      router.push("/");
    })
    .catch((error) => {
      console.log(error.code);
      alert(error.message);
    });
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
