<template>
    <div class="questions-ctr">
        <div class="progress">
            <div class="bar" :style="{ width: `${questionsAnswered / questions.length * 100}%` }"></div>
            <div class="status">{{ questionsAnswered }} out of {{ questions.length }} questions answered</div>
        </div>
        <transition-group name="fade" mode="out-in">
            <div class="single-question" v-for="(question, qi) in questions"
            v-show="answerSelected === -1 && questionsAnswered === qi" 
            :key="question.question.text">
                <div class="question">{{ question.question.text }}</div>
                <div class="answers">
                    <div class="answer" v-for="(answer, ai) in question.question.options" :key="ai" @click.prevent="selectAnswer(ai == 0, ai)">{{ answer.text }}</div>
                </div>
            </div>
        </transition-group>
        <transition-group name="fade" mode="out-in">
            <div v-if="answerSelected !== -1">
                <div class="single-feedback" v-for="(question, qi) in questions"
                v-show="questionsAnswered === qi" 
                :key="question.question.text">
                    <div class="question">{{ question.question.text }}</div>
                    <div class="answer">
                        You selected <strong>{{ questions[questionsAnswered].question.options[answerSelected].text }}</strong>
                        <br />
                        <div class="answer" :style="backgroundColor" v-for="(answer, ai) in question.question.options" v-show="answerSelected === ai" :key="answer.text">{{ answer.answer }}</div>
                        <br />
                        <hr />
                        <button @click="nextQuestion" class="next-btn" style="font-size: 1.2em">Next Question</button>
                    </div>
                    <div></div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    name: "AppQuestion",
    props: ['questions', 'questionsAnswered', 'answerSelected'],
    emits: ['question-answered', 'next-question'],
    methods: {
        selectAnswer(is_correct, selected) {
            console.log(is_correct + ' ' + selected);
            this.$emit('question-answered', is_correct, selected);
        },
        
        nextQuestion() {
            console.log('next question');
            this.$emit('next-question');
        }
    },
    computed: {
        backgroundColor() {
                if(this.answerSelected === 0){
                    return {backgroundColor: 'lightgreen', fontSize: '1.5em'};
                } else {
                    return {backgroundColor: 'lightcoral', fontSize: '1.5em'};
                }
        }
    }
}
</script>

<style>

</style>