<template>
  <transition-group name="fade" mode="out-in">
    <div class="single-question" v-if="answerSelected === -1">
      <div class="question">{{ question.text }}</div>
      <div class="answers">
        <div
          class="answer"
          v-for="(answer, ai) in question.options"
          :key="ai"
          @click.prevent="selectAnswer(ai == 0, ai)"
        >
          {{ answer.text }}
        </div>
      </div>
    </div>
  </transition-group>
  <transition-group name="fade" mode="out-in">
    <div v-if="answerSelected !== -1">
      <div class="single-feedback">
        <div class="question">{{ question.text }}</div>
        <div class="answer">
          You selected
          <strong>{{ question.options[answerSelected].text }}</strong>
          <br />
          <div
            class="answer"
            :style="backgroundColor"
            v-for="(answer, ai) in question.options"
            v-show="answerSelected === ai"
            :key="answer.text"
          >
            {{ answer.answer }}
          </div>
          <br />
          <hr />
          <button
            @click="nextQuestion"
            class="next-btn"
            style="font-size: 1.2em"
          >
            Next Question
          </button>
        </div>
        <div></div>
      </div>
    </div>
  </transition-group>
</template>

<script>
/* eslint-disable */
import { reactive, computed, onMounted, ref } from "vue";
import { findQuiz, getQuestion, getQuiz, useLoadQuizzes } from "@/firebase";

export default {
  name: "QuizQuestion",
  props: ["questionID", "questionsAnswered", "answerSelected"],
  emits: ["question-answered", "next-question", "loadQuestionData"],
  setup(props) {
    const question = reactive({
      text: "",
      type: "fact",
      order: "numerical",
      limit: 4,
      options: [],
    });

    //onMounted(this.loadQuestionData(props.questionID));

    return { question };
  },
  watch: {
    questionID: function (newQuestionID, oldQuestionID) {
      console.log(newQuestionID);
      if (newQuestionID !== oldQuestionID) {
        this.loadQuestionData(newQuestionID);
      }
    },
  },
  methods: {
    loadQuestionData(id) {
      (async () => {
        console.log("loadQuestionData");
        if (this.questionID == null) return;
        const questionDB = await getQuestion(this.questionID);
        console.log(questionDB, this.questionID);
        this.question.text = questionDB.text;
        this.question.type = questionDB.type;
        this.question.order = questionDB.order;
        this.question.limit = questionDB.limit;
        this.question.options = questionDB.options;
      })();
    },

    selectAnswer(is_correct, selected) {
      console.log(is_correct + " " + selected);
      this.$emit("question-answered", is_correct, selected);
    },

    nextQuestion() {
      console.log("next question");
      this.$emit("next-question");
    },
  },
  computed: {
    backgroundColor() {
      if (this.answerSelected === 0) {
        return { backgroundColor: "lightgreen", fontSize: "1.5em" };
      } else {
        return { backgroundColor: "lightcoral", fontSize: "1.5em" };
      }
    },
  },
};
</script>

<style></style>
