<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
  <QuestionList />
</template>

<script>
/* eslint-disable */
import UserCreate from "@/components/UserCreate.vue";
import UserList from "@/components/UserList.vue";
import QuestionList from "@/components/QuestionList.vue";
import QuestionCreate from "@/components/QuestionCreate.vue";

export default {
  name: "QuestionListView",
  components: { UserCreate, UserList, QuestionList, QuestionCreate },
};
</script>
