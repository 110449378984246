<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
  <QuestionCreate />
</template>

<script>
/* eslint-disable */
import QuestionCreate from '@/components/QuestionCreate.vue'

export default {
  name: 'AddQuestionView',
  components: { QuestionCreate }
}
</script>
